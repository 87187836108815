import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as isEqual from "lodash/isEqual";
import WOD from "./WOD";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { MdCheck } from "react-icons/md";
import { db } from "./secrets";
import { utilities } from "../Utilities/Utilities";

export default function Gallery() {
  const [fetched, hasFetched] = useState(false);
  const dispatch = useDispatch();
  const [workouts, changeWorkouts] = useState({});
  const [alert, toggleAlert] = useState(false);
  const [edit, toggleEdit] = useState(true);
  const [focused, changeFocus] = useState();
  const globalState = useSelector((state) => state);
  const userData = useSelector((state) => state.userData);
  const { uid: user } = userData;

  useEffect(() => {
    console.log("Gallery rendered with user data", user);
    //only runs the first time before rendering
    if (!fetched) {
      const getUserData = (result) => {
        //splits the data to global state and logs it
        //If user exists, write to global state
        if (result) {
          changeWorkouts(result);
          console.log("User found: ", result);
          dispatch({ type: "setWorkouts", payload: { ...result } });
        } else {
          console.log("User was not found");
          db.newUser(user);
          db.readUserData(changeWorkouts, { user: user });
        }
      };

      db.readUserData(getUserData, { user: user, path: "workouts" });
      hasFetched(true);
    }
  }, []);

  useEffect(() => {
    if (!isEqual(workouts, {}) || !workouts) {
      dispatch({ type: "setWorkouts", payload: { ...workouts } });
      console.log("UseEffect triggered when workouts changed to: ", workouts);
    }
    //utilities.autoSave(globalState);
  }, [workouts]);

  const alertDelete = (e) => {
    const actualElement = utilities.fixSVG(e.target);
    changeFocus(actualElement.id);
    toggleAlert(!alert);
    return;
  };

  const decide = (e) => {
    const actualElement = utilities.fixSVG(e.target);
    const id = actualElement.id;
    if (id === "yes") {
      removeElement(focused);
    } else {
      toggleAlert(!alert);
    }
  };

  const removeElement = (focusedElement) => {
    const wodCopy = { ...workouts };
    delete wodCopy[focused];
    console.log("deleting: ", focusedElement);
    console.log("Workouts should look like this now: ", wodCopy);
    db.writeUserData({ user: user, path: "workouts" }, { ...wodCopy }, true);
  };

  const workoutsArray = Object.keys(workouts);
  const workoutGallery = workoutsArray.map((workoutName, i) => {
    const workoutObject = workouts[workoutName];
    return (
      <div className='wod'>
        <div className='controls' visible={edit}>
          <Link to={"build/" + workoutName}>
            <FaRegEdit />
          </Link>
          {workoutName !== "default" ? (
            <AiOutlineDelete id={workoutName} onClick={alertDelete} />
          ) : null}
        </div>
        <WOD local={workoutObject} />
      </div>
    );
  });

  return (
    <Container>
      {alert ? (
        <Alert>
          <div>Are you sure you want to delete {workouts[focused].title}?</div>
          <div className='select'>
            <MdCheck id='yes' onClick={decide} />{" "}
            <AiOutlineClose onClick={decide} />
          </div>
        </Alert>
      ) : (
        ""
      )}
      {Object.keys(workouts).length > 0 ? workoutGallery : ""}
    </Container>
  );
}

const Container = styled.div`
  .wod {
    position: relative;
    max-width: 800px;
    margin: auto;
    z-index: 0;
  }
  .controls {
    display: ${(props) => (props.visible ? "none" : "block")};
    z-index: 10;
    position: absolute;
    font-size: 2rem;
    right: 0;
    top: 0;
    padding: 1rem 2rem;
  }
  .controls a {
    z-index: 10;
    color: white;
    text-decoration: none;

    :visited {
      color: white;
    }
  }
`;

const Alert = styled.div`
  background: #282c34;
  z-index: 999;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  top: 0;
  .select {
    font-weight: bold;
    font-size: 1.7rem;
    > * {
      padding: 1rem;
    }
  }
`;
