import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import "firebase/database";
import { db, defaultObject } from "./secrets";

export default function WOD(props) {
  //const workout = props.local;
  const { slug } = useParams();
  const [workout, setWorkout] = useState(props.local || slug);
  const [isOpen, toggleVisibility] = useState(false);
  //const [hidden, toggleHidden] = useState(false);
  useEffect(() => {
    if (slug) {
      db.readData(setWorkout, { root: "/workouts", path: slug });
    }
    if (props.open) toggleVisibility(props.open);
  }, []);

  return (
    <Container
      className='wodContainer'
      onClick={() => {
        if (!props.fixed) toggleVisibility(!isOpen);
      }}
    >
      <Title className='wodTitle'>{workout.title}</Title>
      {workout.phases
        ? workout.phases.map((phase) => (
            <Phase open={isOpen}>
              <div className='phaseTitle'>{phase.title}</div>
              {phase.exercises
                ? phase.exercises.map((exercise) => {
                    return <div className='exercise'>{exercise}</div>;
                  })
                : ""}
            </Phase>
          ))
        : ""}
    </Container>
  );
}

const Phase = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem; /* padding: 0 1rem 1rem calc(20% - 1rem); */
  .phaseTitle {
    font-weight: bold;
    padding: 1rem;
    /* width: 100%; */
    box-sizing: border-box;
  }
`;

const Title = styled.div`
  font-size: 2rem;
  font-family: "Shadows Into Light Two", cursive;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 1rem auto;
  font-size: 2.5rem;
  position: relative;
  font-family: "Reenie Beanie", cursive;
  border: 2px white solid;
  /* margin: 1rem; */
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:nth-child(1) {
    padding-top: 2.5rem;
  }
  .exercise {
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
  }
`;
