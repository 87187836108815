import * as firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCqhiAp3FFB03iYnAhqkmGBs-e-dkw2fps",
  authDomain: "wodly-273015.firebaseapp.com",
  databaseURL: "https://wodly-273015.firebaseio.com",
  projectId: "wodly-273015",
  storageBucket: "wodly-273015.appspot.com",
  messagingSenderId: "78797800521",
  appId: "1:78797800521:web:2da267275737af44786b98",
  measurementId: "G-WSVCJC10K7",
};

const defaultObject = {
  default: {
    title: "new workout",
    phases: [
      {
        title: "new phase",
        exercises: ["new exercise"],
      },
    ],
  },
};

const db = {
  /*   buildPath: (pathObject) => {
    let formattedQuery = "/users";
    for (const key in pathObject) {
      if (pathObject.hasOwnProperty(key)) {
        if (key !== "") {
          const element = pathObject[key];
          formattedQuery = formattedQuery + "/" + element;
        }
      }
    }
    console.log("formatted query is", formattedQuery);
    return formattedQuery;
  }, */

  buildPath: (pathObject) => {
    const { root, ...rest } = pathObject;

    let formattedQuery = root ? root : "/users";

    for (const key in rest) {
      const line = rest[key];
      console.log("key is: ", line);
      formattedQuery = formattedQuery + `/${line}`;
    }

    console.log("formatted query is", formattedQuery);
    return formattedQuery;
  },

  readUserData: async (
    writeToState,
    query = {
      root: "/users",
      user: "alejandro",
      path: "workouts",
      target: "default",
    }
  ) => {
    try {
      console.log("Reading user data...");
      const database = await firebase.database();
      const reference = await database.ref(db.buildPath(query));
      const response = await reference.once("value");
      const value = await response.val();
      writeToState(value);
    } catch (error) {
      console.log(error);
    }
  },

  newUser: (uid = "testUser") => {
    const getIt = (data) => {
      console.log(data);
      db.writeUserData({}, { ...data, [uid]: { workouts: defaultObject } });
    };
    db.readUserData(getIt, {});
  },

  addData: async (
    query = { user: "alejandro", path: "workouts", target: "" },
    data = defaultObject.default,
    reload = true
  ) => {
    //get the data
    //ex get workout array
    const database = await firebase.database();
    const reference = await database.ref(db.buildPath(query));
    const response = await reference.once("value");
    const value = await response.val();

    //generate a new key
    const key = await reference.child(db.buildPath(query)).push().key;
    console.log(key);
    reference.update({ ...value, [key]: data });
    reload
      ? (window.location.pathname = "/build/" + key)
      : console.log("added data");
  },
  writeUserData: async (query, data, reload = true) => {
    const formattedQuery = db.buildPath(query);
    const database = await firebase.database();
    const reference = await database.ref(formattedQuery);
    //const response = await reference.once("value");
    //const parentNode = await response.val();
    //console.log('Parent node is:', parentNode);
    reference
      .set({ ...data })
      .then(() =>
        reload ? window.location.reload() : console.log("wrote data")
      );
    //reload ? window.location.reload() : console.log("wrote data");
  },

  writeData: async (query, data, reload = true) => {
    const formattedQuery = db.buildPath(query);
    const database = await firebase.database();
    const reference = await database.ref(formattedQuery);
    reference
      .set({ ...data })
      .then(() =>
        reload ? window.location.reload() : console.log("wrote data")
      );
  },

  readData: async (
    writeToState,
    query = {
      root: "/users",
      user: "alejandro",
      path: "workouts",
      target: "default",
    },
    filter
  ) => {
    try {
      console.log("Reading data...");
      const database = await firebase.database();
      const reference = await database.ref(db.buildPath(query));
      const response = await reference.once("value");
      const value = await response.val();
      writeToState(value);
    } catch (error) {
      console.log("/// error: ", error);
    }
  },

  clearData: (flags) => {
    let reference;
    switch (flags) {
      case "clear":
        reference = {};
        break;

      default:
        reference = defaultObject;
    }

    firebase.database().ref("/users/alejandro/workouts").set(reference);
  },
};

export { firebaseConfig, db, defaultObject };
