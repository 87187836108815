import React, { useEffect, useState } from "react";
import Login from "./Login";
import WOD from "../Components/WOD";
import Gallery from "../Components/Gallery";
import { useSelector, useDispatch } from "react-redux";
import { db } from "./secrets";
import styled from "styled-components";
import { utilities, auth, firebase, Auth } from "../Utilities/Utilities";

export default function Main() {
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.userData.uid);
  const authClass = new Auth({});

  useEffect(() => {
    console.log("main rendered");
    const setUserData = (userData) => {
      dispatch({ type: "setUserData", payload: userData });
    };
    authClass.onLoad(setUserData);
    //db.readUserData(changeUsers, {});
  }, []);

  useEffect(() => {
    console.log("UID changed to ", uid);
  }, [uid]);

  const signOut = () => {
    authClass.signOut();
    dispatch({ type: "setUserData", payload: { uid: "null" } });
  };

  const Wait = () => <div>Please wait</div>;

  return (
    <div>{uid === "null" ? <Login /> : !uid ? <Wait /> : <Gallery />}</div>
  );
}
