import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { FaRegHeart } from "react-icons/fa";
import { Link } from "react-router-dom";
import WOD from "./WOD";
import { colors } from "../styles/components";
import { utilities } from "../Utilities/Utilities";
import { db } from "./secrets";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import "lodash/isEqual";

export default function Workouts() {
  //const { slug } = useParams();
  const dispatch = useDispatch();
  const [workouts, changeWorkouts] = useState({});
  const [focused, changeFocus] = useState(null);
  const globalState = useSelector((state) => state);

  useEffect(() => {
    //const fromLocalStorage = utilities.quickLoad(toGlobalState, false);
    //console.log("/// Retreived from localstorage", fromLocalStorage);
    db.readData(changeWorkouts, { root: "/workouts" });
  }, []);

  useEffect(() => {
    console.log("Workouts changed to ", workouts);
  }, [workouts]);

  const renderWorkouts = () => {
    const isEmpty = isEqual(workouts, {});

    if (!isEmpty && !focused) {
      return Object.keys(workouts).map((workout, i) => {
        const wodObject = workouts[workout];
        if (wodObject.title !== "new workout") {
          return (
            <div className='wods'>
              <Link to={`/wod/${workout}`}>{wodObject.title}</Link>
            </div>
          );
        } else {
          return null;
        }
      });
    } else return null;
  };

  useEffect(() => {
    if (focused)
      dispatch({
        type: "setVisible",
        payload: { type: "navbar", value: false },
      });
  }, [focused]);

  const closeWOD = () => {
    changeFocus(null);
    dispatch({ type: "setVisible", payload: { type: "navbar", value: true } });
  };

  const liked = () => {
    console.log(`You're about to get: `, focused);
    db.addData(
      {
        user: globalState.userData.uid,
        path: "liked",
      },
      focused,
      false
    );
  };

  return (
    <Container>
      {
        <div>
          {renderWorkouts()}
          <div className=''>
            {focused ? (
              <div>
                <div className='icons'>
                  <IoMdClose onClick={closeWOD} />
                  <FaRegHeart className='heart' onClick={liked} />
                </div>
                <WOD local={focused} open={true} fixed={true}></WOD>
              </div>
            ) : null}
          </div>
        </div>
      }
    </Container>
  );
}

const Container = styled.div`
  a {
    color: ${colors.white};
    text-decoration: none;
  }
  display: grid;
  text-align: center;
  .wods {
    padding: 1rem;
    :hover {
      background: ${colors.darker};
      color: ${colors.white};
    }
  }
  .icons {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 2rem;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    * {
      padding: 1rem;
    }
  }
  .heart {
    transition: color 0.7s ease-in-out;

    :hover {
      color: rgb(100%, 33.9%, 33.9%);
    }
  }
  .wodContainer {
    background: #282c34;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    > div {
      padding: 0.5rem;
      margin: 0.5rem;
    }
  }
`;
