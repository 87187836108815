import styled from "styled-components";

export const colors = {
  bg: "#282C34",
  darker: "#5D737E",
  middle: "#6D6D6D",
  light: "#5bc0be",
  accent: "#F5F5F5",
  almostwhite: "#e5e5e5",
  white: "#fafaff",
};

export const Nav = styled.nav`
  #logo {
    font-family: "Reenie Beanie", cursive;
    font-weight: bold;
    font-size: 2rem;
    letter-spacing: 0.1rem;
  }

  font-family: Muli;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: space-between;
  align-items: center;
  /* border: aliceblue 2px solid; */
  background: ${colors.darker};
  padding: 1rem;
  a {
    color: ${colors.white};
    text-decoration: none;
  }
  #section {
    display: ${(props) => (props.open ? "grid" : "none")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    justify-content: center;
    box-sizing: border-box;
    padding: 20%;
    background: ${colors.darker};
    align-items: center;
    text-align: center;
    font-weight: bold;
    z-index: 888;
    font-size: 2rem;

    > a,
    div {
      padding: 1rem;
      text-decoration: none;
      color: ${colors.white};
    }
  }
  #logo,
  #burger {
    z-index: 999;
  }
`;

const Wrapper = styled.div``;
