import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducer";

const initialState = {
  workouts: {},
  focused: {},
  userData: {},
  showHide: { navbar: true },
};

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["showHide"], // navigation will not be persisted
};

const pReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  pReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);

export default store;
