import React, { useEffect, useState } from "react";
import { Nav } from "../styles/components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "../Utilities/Utilities";
import { GiHamburgerMenu } from "react-icons/gi";

export default function Navbar({ show, open }) {
  const dispatch = useDispatch();
  const authClass = new Auth({});
  const uid = useSelector((state) => state.userData.uid);
  const [isOpen, toggleOpen] = useState(open);

  useEffect(() => {
    console.log("Nav triggered uid ", uid);
    console.log("Nav triggered uid ", typeof uid);
  }, [uid]);

  const signOut = () => {
    authClass.signOut();
    dispatch({ type: "setUserData", payload: { uid: "null" } });
  };

  const logState = {
    in: { display: "Sign out", link: "" },
    out: { display: "Login", link: "/" },
  };

  const clickOnLink = (e) => {
    console.log(e.target);
    toggleOpen(!isOpen);
  };

  return (
    <Nav show={show} open={isOpen} onClick={clickOnLink}>
      <Link id='logo' to='/'>
        <div>Wodly</div>
      </Link>
      <GiHamburgerMenu id='burger' onClick={clickOnLink} />
      <div id='section'>
        <Link to='/timer'>
          <div>Timer</div>
        </Link>
        <Link to='/workouts'>
          <div>WOD</div>
        </Link>
        <Link
          to='/'
          onClick={
            uid
              ? signOut
              : () => {
                  return;
                }
          }
        >
          <div>
            {uid === "null" ? logState.out.display : logState.in.display}
          </div>
        </Link>
      </div>
    </Nav>
  );
}
