// Reducer
function reducer(state, action) {
  const payload = action.payload;
  const functionType = action.functionType;
  const element = action.element;
  switch (action.type) {
    case "setWorkouts":
      return { ...state, workouts: payload };
    case "setFocus":
      return { ...state, focused: payload };
    case "setUserData":
      return { ...state, userData: payload };
    case "setVisible":
      return {
        ...state,
        showHide: { ...state.showHide, [payload.type]: payload.value },
      };
    default:
      return state;
  }
}

export default reducer;
