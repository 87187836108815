import React, { useState, useEffect } from "react";
import useInterval from "../Utilities/hooks";
import numeral from "numeral";
import styled from "styled-components";

const Timer = () => {
  const [status, setStatus] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const [start, setStart] = useState(0);
  const [sounds, setSounds] = useState({});
  const [beeper, setBeeper] = useState({
    on: 45,
    off: 15,
    round: 1,
    maxRounds: 1,
  });

  useEffect(() => loadSounds(), []);

  const loadSounds = (bank = "beeps") => {
    import("../Utilities/sound").then((soundModules) => {
      const loadedSounds = {};
      const soundBank = soundModules[bank];
      for (const sound in soundBank) {
        if (typeof sound === "string") {
          const soundObject = new Audio(soundBank[sound]);
          loadedSounds[sound] = soundObject;
        }
      }
      setSounds(loadedSounds);
    });
  };

  /*   useEffect(() => {
    console.log("sound changed to: ", sounds);
  }, [sounds]); */

  const playBuffered = (type) => {
    sounds[type].play();
  };

  useInterval(() => {
    // Your custom logic here
    if (status) {
      refresh();
    }
  }, 100);

  const refresh = () => {
    if (status) {
      const total = Math.floor(Date.now() - start) / 1000;
      const formatted = numeral(total).format("00:00:00");
      const split = formatted.split(":");
      let res = { min: split[1], sec: split[2] };

      const present = Number(res.sec);

      if (present === beeper.on) {
        playBuffered("high");
      }

      if (present === beeper.on + beeper.off) {
        playBuffered("low");
        res = { min: "00", sec: "00" };
        setBeeper({ ...beeper, round: beeper.round + 1 });
        setTimer("00:00");
        startTimer(beeper.round);
      }

      if (beeper.round === beeper.maxRounds + 1) {
        setBeeper({ ...beeper, round: beeper.maxRounds });
        setStatus(false);
      }

      setTimer(`${res.min}:${res.sec}`);
    }
  };

  const handleChange = (e) => {
    const value = Number(e.target.value);
    const id = e.target.id;
    setBeeper({ ...beeper, [id]: value });
    return;
  };

  const startTimer = (round = 1) => {
    //setBeeper({ ...beeper, round: 1 });
    setStatus(true);
    setStart(Date.now());
  };

  const hasStarted = () => {
    if (status) {
      return <button onClick={() => setStatus(false)}>Stop</button>;
    }
    if (!status && start !== 0) {
      return <button onClick={() => setStatus(true)}>Resume</button>;
    }
  };

  const focus = (e) => {
    //const value = Number(e.target.value);
    const id = e.target.id;
    setBeeper({ ...beeper, [id]: 0 });
  };

  return (
    <Container>
      <Board>{`Round: ${beeper.round} Time: ${timer}`}</Board>
      <Controls>
        <Label>Rounds</Label>
        <input
          id='maxRounds'
          onChange={handleChange}
          value={beeper.maxRounds}
        />
        <Label>Time on</Label>
        <input
          id='on'
          onChange={handleChange}
          onFocus={focus}
          placeholder={45}
          value={beeper.on}
        />
        <Label>Time off </Label>
        <input
          id='off'
          onChange={handleChange}
          onFocus={focus}
          placeholder={15}
          value={beeper.off}
        ></input>
        <div id='buttons'>
          {hasStarted()}
          <button onClick={startTimer}>{start ? "Restart" : "Start"}</button>
        </div>
      </Controls>
    </Container>
  );
};

export default Timer;

const Container = styled.div`
  * {
    font-family: Inter;
  }
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: 75%;
  max-width: 500px;
  /* border: aliceblue 2px solid; */
  margin: auto;
`;

const Board = styled.h3`
  text-align: center;
  font-size: 4rem;
  border: 2px white solid;
  border-radius: 10px;
  padding: 2rem;
`;

const Controls = styled.div`
  > div {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin: 1rem;
  }
  > input {
    width: -webkit-fill-available;
    text-align: center;
    font-size: 2rem;
    padding: 0.25rem;
    background: none;
    border: none;
    color: white;
  }
  #buttons {
    display: grid;
    padding: 1rem;
  }
  button {
    font-size: 1.2rem;
    border-radius: 15px;
    padding: 1rem;
    :active,
    :focus {
      border: none;
      outline: none;
    }
  }
`;

const Label = styled.div``;
