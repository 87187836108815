import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { FaGoogle } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { colors } from "../styles/components";
import { utilities, auth, firebase, Auth } from "../Utilities/Utilities";

export default function Login() {
  const [debug, toggleDebug] = useState(false);
  const [useEmail, toggleUseEmail] = useState(false);
  const [email, changeEmail] = useState("");
  const [password, changepassword] = useState("");
  const dispatch = useDispatch();
  const authClass = new Auth({ email, password });

  useEffect(() => {
    console.log("Login rendered");
  });

  useEffect(() => {
    //Set listener for user changes
    /*     const setUserData = (userData) => {
      dispatch({ type: "setUserData", payload: userData });
    }; */
    //authClass.onLoad(setUserData);
    document.addEventListener("keydown", function (e) {
      if (e.keyCode === 68 && e.altKey) {
        toggleDebug(!debug);
      }
    });
  }, []);

  useEffect(() => {
    authClass.setData({ email, password });
  }, [email, password]);

  const handleChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    id.includes("email") ? changeEmail(value) : changepassword(value);
  };

  const debugTools = () => {
    return (
      <div>
        <Input
          id='password'
          type='password'
          placeholder={"password"}
          value={password}
          onChange={handleChange}
        ></Input>
        <BWrap>
          <Button
            id='signUp'
            onClick={() => authClass.authenticate("", { email, password })}
          >
            Sign up
          </Button>
        </BWrap>
        <BWrap>
          <Button
            id='login'
            onClick={() => authClass.authenticate("login", { email, password })}
          >
            Sign in
          </Button>
        </BWrap>
        <BWrap>
          <Button onClick={() => firebase.auth().signOut()}>Sign out</Button>
        </BWrap>
        <BWrap>
          <Button
            onClick={() => {
              const user = firebase.auth().currentUser;
              const token = user.getIdToken(true);
              user
                ? console.log(user, token)
                : console.log("no user logged in");
            }}
          >
            Get user
          </Button>
        </BWrap>
      </div>
    );
  };
  const formattedEmail = (str) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str);
  };

  const logIn = () => {
    return (
      <Container>
        <Title>Log in</Title>
        <Subtitle>Short and sweet</Subtitle>
        {useEmail ? (
          <Input
            id='email'
            type='email'
            placeholder={"email"}
            value={email}
            onChange={handleChange}
          ></Input>
        ) : null}
        <BWrap
          onClick={() => {
            if (!useEmail) {
              toggleUseEmail(!useEmail);
            } else if (useEmail) {
              if (!formattedEmail(email)) {
                alert("Please check your email formatting");
              } else {
                authClass.sendConfirmationEmail({ email });
                alert(
                  `We sent an email to ${email} please confirm and get back to this page`
                );
              }
            }
          }}
        >
          <Icon>
            <MdEmail />
          </Icon>
          <Button>Email login</Button>
        </BWrap>
        <BWrap onClick={authClass.googleLogin}>
          <Icon>
            <FaGoogle />
          </Icon>
          <Button>Google login</Button>
        </BWrap>
        {debug ? debugTools() : null}
      </Container>
    );
  };

  return <div>{logIn()}</div>;
}

const Container = styled.div`
  font-family: Muli;
  display: flex;
  justify-content: center;
  align-items: center;
  font-display: center;
  flex-flow: column;
  margin: 5%;
  background: ${colors.accent};
  border-radius: 20px;
  padding: 0 5% 1rem 5%;
  color: black;
  text-align: center;
  > * {
    padding: 1rem 0;
    width: 100%;
    box-sizing: border-box;
  }
`;

const BWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  margin: 0.5rem 0;
  border-radius: 20px;
  border: 1px solid black;
  background: ${colors.accent};
  color: #282c34;
  width: 100%;
  button {
    font-size: 1rem;
    font-weight: bold;
    border: 0;
  }
`;

const Icon = styled.div`
  padding: 0 1rem;
`;

const Title = styled.div`
  font-size: 2rem;
  text-align: center;
  color: black;
`;

const Subtitle = styled.div``;

const Button = styled.button`
  background: ${colors.accent};
`;

const Input = styled.input`
  font-size: 1rem;
  border-radius: 10px;
  margin: 0 0 0.5rem 0;
  padding: 1rem;
`;
