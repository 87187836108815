import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import "firebase/database";
import { db } from "./secrets";
import { IoIosClose } from "react-icons/io";
import { utilities } from "../Utilities/Utilities";
import { FaRegTrashAlt } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";

export default function Build() {
  const defaultData = {
    title: "",
    phases: [
      {
        title: "",
        exercises: [""],
      },
    ],
  };
  const user = useSelector((state) => state.userData.uid);
  //const [user, setUser] = useState("");
  const { slug } = useParams();
  const [workout, changeWorkout] = useState(defaultData);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("/// WORKOUT CHANGED TO : ", workout);
  }, [workout]);

  useEffect(() => {
    //Pick between new workout or existing workouts
    console.log("///// User is: ", user, "///Slug is :", slug);
    console.log("slug is", slug);

    switch (slug) {
      case "default":
        db.readUserData(changeWorkout);
        return;
      default:
        db.readUserData(changeWorkout, {
          user: user,
          path: "workouts",
          target: slug,
        });
        return;
    }
  }, []);

  const handleClose = (e) => {
    const workoutCopy = { ...workout };
    //const id = e.target;
    let id;
    let inputParent;
    if (e.target.tagName === "svg") {
      id = e.target.parentElement.id;
      inputParent = e.target.parentElement.parentElement;
    } else if (e.target.tagName === "path") {
      id = e.target.parentElement.parentElement.id;
      inputParent = e.target.parentElement.parentElement.parentElement;
    } else {
      id = e.target.id;
      inputParent = e.target.parentElement;
    }

    if (id.includes("title")) {
      changeWorkout({ ...workout, title: "" });
      inputParent.focus();
      return;
    }
    if (id.includes("phase")) {
      const index = id.split(" ")[1].replace("_clear", "");
      console.log("Cleared...", id);
      workoutCopy.phases[index].title = "";
    }

    if (id.includes("exercise")) {
      const phaseIndex = id.split(" ")[1];
      const exerciseIndex = id.split(" ")[2];
      workoutCopy.phases[phaseIndex].exercises[exerciseIndex] = "";
    }

    changeWorkout({ ...workoutCopy });
    inputParent.focus();
  };

  const handleInput = (e) => {
    console.log("clicked on: ", e.target);
    let elem = e;
    const id = elem.target.id;
    const value = elem.target.value;
    const workoutCopy = { ...workout };
    /* const focusInput = (e) => {
      document.querySelector(e).focus();
    }; */

    //Change wod title
    if (id === "title") {
      changeWorkout({ ...workout, [id]: value });
      return;
    }

    //Change phase title
    if (id.split(" ").length === 2) {
      const index = id.split(" ")[1];
      workoutCopy.phases[index].title = value;
    }

    //Change exercise
    if (id.split(" ").length === 3) {
      const phaseIndex = Number(id.split(" ")[1]);
      const exerciseIndex = Number(id.split(" ")[2]);
      workoutCopy.phases[phaseIndex].exercises[exerciseIndex] = value;
    }
    changeWorkout(workoutCopy);
  };

  const addPhase = () => {
    //Change WOD title
    changeWorkout({
      ...workout,
      phases: [...workout.phases, { title: "New phase", exercises: [] }],
    });
  };

  const addExercise = (e) => {
    const id = Number(e.target.id.split(" ")[1]);
    console.log(id);
    const modEx = workout.phases[id].exercises;
    modEx.push("new ex " + id);
    console.log(modEx);
    const modifiedPhases = workout.phases.map((phase, i) => {
      if (i === id) {
        return { ...phase, exercises: modEx };
      }
      return phase;
    });
    changeWorkout({ ...workout, phases: modifiedPhases });
  };
  const removePhase = (target) => {
    const wodCopy = workout;
    const id = Number(target.id.split(" ")[1]);
    const filtered = wodCopy.phases.filter((el, i) => {
      return i !== id;
    });
    changeWorkout({ ...workout, phases: filtered });
  };
  const removeExercise = (target) => {
    const wodCopy = workout;
    const id = target.id;
    const phase = Number(id.split(" ")[1]);
    const exercise = Number(id.split(" ")[3]);
    wodCopy.phases[phase].exercises = workout.phases[phase].exercises.filter(
      (el, i) => {
        return i !== exercise;
      }
    );
    changeWorkout({ ...wodCopy });
  };

  const submitData = () => {
    //Add a new workout
    if (slug === "default") {
      db.addData({ user: user, path: "workouts", target: "" }, workout);
    }

    //Write workout
    else {
      db.writeUserData({ user: user, path: "workouts", target: slug }, workout);
    }
    //db.writeUserData(workout);
  };

  const preview = () => {
    return (
      <Preview>
        <Title>{workout.title}</Title>
        <div>
          {workout.phases
            ? workout.phases.map((phase) => (
                <Phase>
                  <div className='phaseTitle'>{phase.title}</div>
                  {phase.exercises
                    ? phase.exercises.map((exercise) => {
                        return <div>{exercise}</div>;
                      })
                    : null}
                </Phase>
              ))
            : null}
        </div>
      </Preview>
    );
  };

  const back = () => {
    window.location.pathname = "/";
  };

  const fixSVG = (e) => {
    const target = utilities.fixSVG(e.target);
    const id = target.id;
    if (id.includes("removePhase")) removePhase(target);
    if (id.includes("removeExercise")) removeExercise(target);
  };

  return (
    <Container>
      <IoIosArrowBack onClick={back} />
      <Builder>
        <div>Title</div>
        <InputContainer>
          <Input
            type='text'
            id='title'
            value={workout.title}
            onChange={handleInput}
          />

          <CloseButton id={"title_clear"} onClick={handleClose}>
            <IoIosClose />
          </CloseButton>
        </InputContainer>
        <div>
          <PushButton onClick={addPhase}>add phase</PushButton>
        </div>
        {workout.phases
          ? workout.phases.map((phase, i) => (
              <Phase>
                <div className='phaseBox'>
                  <InputContainer>
                    <Input
                      type='text'
                      id={"phase " + i}
                      value={phase.title}
                      onChange={handleInput}
                    />
                    <CloseButton id={"phase_clear " + i} onClick={handleClose}>
                      <IoIosClose />
                    </CloseButton>
                  </InputContainer>

                  <ButtonWrapper>
                    <FaRegTrashAlt id={"removePhase " + i} onClick={fixSVG} />
                  </ButtonWrapper>

                  <PushButton id={"addExercise " + i} onClick={addExercise}>
                    add exercise
                  </PushButton>
                </div>

                {phase.exercises
                  ? phase.exercises.map((exercise, j) => (
                      <Exercises>
                        <InputContainer>
                          <Input
                            type='text'
                            id={"phase " + i + " " + j}
                            value={exercise}
                            onChange={handleInput}
                          />
                          <CloseButton
                            id={"exercise_clear " + i + " " + j}
                            onClick={handleClose}
                          >
                            <IoIosClose />
                          </CloseButton>
                        </InputContainer>
                        <ButtonWrapper>
                          <FaRegTrashAlt
                            id={"phase " + i + " removeExercise " + j}
                            onClick={fixSVG}
                          />
                        </ButtonWrapper>
                      </Exercises>
                    ))
                  : null}
              </Phase>
            ))
          : null}
        <BigButton onClick={submitData}>Submit</BigButton>
        <BigButton
          onClick={() => {
            //db.clearData();
            window.location.reload();
            return false;
          }}
        >
          Clear
        </BigButton>
      </Builder>
      {preview()}
    </Container>
  );
}

const Phase = styled.div`
  .phaseTitle {
    font-weight: bold;
    padding: 1rem 0;
  }
  .phaseBox {
    display: grid;
    grid-template-columns: 70% 1fr 1fr;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;

  border-radius: 5px;
`;

const BigButton = styled.button`
  padding: 1rem;
  font-weight: bold;
  font-size: 1rem;
  margin: 0.5rem 0;
  color: white;
  background: #282c34;
`;

const InputContainer = styled.div`
  position: relative;

  > span {
    font-size: 2rem;
  }
  input {
    margin: 0;
    border: 0;
    padding: 1rem 0;
    width: 100%;
    font-size: 1rem;
  }
`;

const Input = styled.input`
  padding: 0.5rem;
  background: transparent;
  color: white;
`;

const Exercises = styled.div`
  display: grid;
  grid-template-columns: 90% 1fr;
  padding: 0;
`;

const Title = styled.div`
  font-size: 2rem;
  font-family: "Shadows Into Light Two", cursive;
  font-weight: bold;
  text-align: center;
`;

const PushButton = styled.button`
  background: white;
  border-radius: 5px;
`;

const Preview = styled.div`
  border: 2px white solid;
  margin: 1rem;
  padding: 1rem;
`;
const Builder = styled.div`
  display: grid;
  margin: 1rem;
`;
const Container = styled.div`
  font-size: 2.5rem;
  font-family: "Reenie Beanie", cursive;
  button {
    border-radius: 10px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  font-size: 2rem;
  right: 0;
  top: 0.5rem;
  background: none;
  color: white;
  border: 0;
  padding: 0;
  margin: 0;
`;
