import React, { useEffect, useState } from "react";
import Login from "./Login";
import Gallery from "../Components/Gallery";
import isEqual from "lodash/isEqual";
import { db } from "./secrets";
import { useSelector, useDispatch } from "react-redux";

export default function Test() {
  const [workouts, setWorkouts] = useState({});
  const user = useSelector((state) => state.userData.uid);

  const renderWorkouts = () => {
    const isEmpty = isEqual(workouts, {});

    if (!isEmpty) {
      return Object.keys(workouts).map((workout, i) => {
        const wodObject = workouts[workout];
        if (wodObject.title !== "new workout" && wodObject.editors) {
          if (wodObject.editors.hasOwnProperty(user))
            return (
              <div
                className='wods'
                onClick={() => {
                  console.log("clicked on", wodObject);
                }}
              >
                {wodObject.title}
              </div>
            );
        } else {
          return null;
        }
      });
    } else return null;
  };

  useEffect(() => {
    //before render

    db.readData(setWorkouts, {
      root: "/workouts",
    });
    console.log("Test rendered");
  }, []);
  useEffect(() => {
    console.log("workouts changed to :", workouts);
  }, [workouts]);

  return <div>{renderWorkouts()}</div>;
}
