import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import WOD from "./Components/WOD";
import Workouts from "./Components/Workouts";
import Build from "./Components/Build";
import Catch from "./Components/Catch";
import Test from "./Components/Test";
import Main from "./Components/Main";
import Timer from "./Components/Timer";
import Gallery from "./Components/Gallery";
import Navbar from "./Components/Navbar";

import "firebase/database";

function App() {
  const show = useSelector((state) => state.showHide.navbar);
  return (
    <Router>
      <Navbar show={show} />
      <Switch>
        <Route exact path='/main'>
          <Main />
        </Route>
        <Route exact path='/timer'>
          <Timer />
        </Route>
        <Route exact path='/catch'>
          <Catch />
        </Route>
        <Route exact path='/'>
          <Main />
        </Route>
        <Route exact path='/test'>
          <Test />
        </Route>
        <Route exact path='/gallery'>
          <Gallery />
        </Route>
        <Route path='/build/:slug'>
          <Build />
        </Route>
        <Route path='/wod/:slug'>
          <WOD open={true} fixed={true} />
        </Route>
        <Route path='/workouts'>
          <Workouts />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
